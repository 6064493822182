var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.isColumn ? "flex mv3 justify-between mt4" : "flex flex-column",
    },
    [
      !_vm.isColumn
        ? _c(
            "div",
            [_c("OverviewBanner", { attrs: { isLargeBanner: false } })],
            1
          )
        : _vm._e(),
      _vm.isColumn
        ? _c("div", { staticClass: "flex justify-between" }, [
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(0),
                _c(
                  "div",
                  [
                    _vm.$permissions.isCreateEpisodeAllowed()
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast w-100",
                          attrs: {
                            text: "Upload a new track",
                            onClick: _vm.handleUploadAudio,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  [
                    _vm.$permissions.isPublishPodcastShowAllowed("")
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 w-100",
                          attrs: {
                            text: "More about publishing podcasts",
                            onClick: _vm.handlePublish,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column ba b--adori-gray w-30 justify-between",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  [
                    _vm.$permissions.isViewInsightsShowAllowed("")
                      ? _c("BaseButtonRed", {
                          staticClass: "add-podcast mr2 ph4 w-100 mt3",
                          attrs: {
                            text: "Adori Insights demo",
                            onClick: _vm.handleDemoInsights,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
          ])
        : _c("div", [
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column justify-between ba b--adori-gray",
                class: _vm.isColumn ? "w-30" : "",
              },
              [
                _vm._m(3),
                _vm.$permissions.isCreateEpisodeAllowed()
                  ? _c("BaseButtonRed", {
                      staticClass: "mb3 w-100",
                      attrs: {
                        text: "Upload a new track",
                        onClick: _vm.uploadNewAudioTrack,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column justify-between ba b--adori-gray",
                class: _vm.isColumn ? "w-30" : "",
              },
              [
                _vm._m(4),
                _vm.$permissions.isCreateTagShowAllowed("")
                  ? _c("BaseButtonRed", {
                      staticClass: "mb3 w-100",
                      attrs: {
                        text: "Create a new tag",
                        onClick: _vm.createTag,
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass:
                  "pa4 bg-adori-light-gray br2 mb4 flex flex-column justify-between ba b--adori-gray",
                class: _vm.isColumn ? "w-30" : "",
              },
              [
                _c("div", { staticClass: "f4 mb4 light-gray lh-title" }, [
                  _vm._v("Frequently asked questions"),
                ]),
                _c("BaseButtonBorder", {
                  staticClass: "mv3 w-100",
                  attrs: { text: "View all FAQs", onClick: _vm.viewFAQs },
                }),
              ],
              1
            ),
          ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Adorify your audio"),
      ]),
      _c("div", { staticClass: "mb3 flex items-center" }, [
        _c("img", {
          staticStyle: { width: "150px", height: "115px", margin: "auto" },
          attrs: { src: require("@/assets/no-episodes.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Transcripts with keyword detection."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Automatically suggested interactive tags are visualized within the audio."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Publish audio as interactive podcasts, embeddable web content, and more!"
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Publish a podcast in 3 steps"),
      ]),
      _c("div", { staticClass: "mb3 flex w-100" }, [
        _c("img", {
          staticStyle: { width: "150px", margin: "auto", height: "115px" },
          attrs: { src: require("@/assets/no-podcasts.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Manage unlimited podcast shows."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Distribute shows to all leading podcast directories and platforms."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Embed interactive web-players of a podcast on any website."),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title tc" }, [
        _vm._v("Access advanced analytics"),
      ]),
      _c("div", { staticClass: "mb3 flex items-center" }, [
        _c("img", {
          staticStyle: { width: "150px", margin: "auto", height: "115px" },
          attrs: { src: require("@/assets/no-insights.svg"), alt: "" },
        }),
      ]),
      _c("div", { staticClass: "w-100 lh-copy silver custom-font-size" }, [
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v("Detailed podcast analytics."),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Downloads, location and device metrics for shows and episodes."
          ),
        ]),
        _c("div", { staticClass: "mb-custom-px" }, [
          _vm._v(
            "Unique engagement analytics for audio consumed in Adori enabled apps."
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title" }, [
        _vm._v("Do more with Adori Studio"),
      ]),
      _c("div", { staticClass: "f5 mb4 silver lh-copy" }, [
        _c("i", [_vm._v("Adorify")]),
        _vm._v(
          " your audio content with 1-click. Generate transcripts, and review auto-suggested interactive\n          tags embedded into your audio hotspots. Distribute your tracks as interactive podcasts, embeddable web\n          content, and more!\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "f4 mb4 light-gray lh-title" }, [
        _vm._v("Interactive tags enhance audio"),
      ]),
      _c("div", { staticClass: "f5 mb4 silver lh-copy" }, [
        _vm._v(
          "\n          Deliver enhanced audio experiences by surfacing contextual visuals, links, real time polls and more. Enable\n          direct monetization from within the audio via sponsor offers, merchandize links, and 1-click direct\n          donation.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }