






































































































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { Getter, Action } from 'vuex-class'
import FAQS from '@/pages/faqs'
import OverviewBanner from '@/components/Overview/OverviewBanner.vue'

@Component({
  components: {
    OverviewBanner,
  },
})
export default class TagsInfoCard extends Vue {
  @Prop(Boolean) isColumn!: boolean
  @Getter defaultAudioCollection!: object
  @Action showCreatePodcast!: any
  @Action showImportPodcast!: any

  get faqs() {
    return FAQS
  }

  uploadNewAudioTrack() {
    this.$store.dispatch('uploadNewAudioTrack')
    this.$router.push('/audio')
  }

  createTag() {
    this.$store.dispatch('showTagEdit')
    this.$router.push('/tags')
  }

  viewFAQs() {
    this.$router.push('/faqs')
  }

  handlePublish() {
    this.$router.push('/publish/podcasts')
  }

  handleUploadAudio() {
    this.$store.dispatch('uploadNewAudioTrack')
  }

  createNewTag() {
    this.$store.dispatch('showTagEdit', null)
  }

  handleDemoInsights() {
    window.open('https://demo.adorilabs.com/insights', '_blank')
  }
}
